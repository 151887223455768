<template>
	<div class="">
		<div v-if="showLabel" class="chart-title mb-3">
			<InlineSvg v-if="iconSrc" :src="iconSrc" class="title-icon" />
			<h4 class="title-label text-truncate text-bold mb-4">{{ label }}</h4>
		</div>
		<div class="chart-wrapper">
			<AppBarChart
				:color="baseColor"
				:showTopN="showTopN"
				:otherLabel="otherLabel"
				:totals="data.totals"
				:loading="!showChart"
				:formatter="formatterFunction"
			/>
		</div>
	</div>
</template>

<script>
	import AppBarChart from '~/components/charts/AppBarChart'
	import commonHelper from '~/global_helper/helpers.js'
	import DateFilteringMixin from '../DateFilteringMixin.js'

	export default {
		props: {
			label: {
				type: String,
				required: false,
			},
			iconSrc: {
				type: String,
				required: false,
			},
			type: {
				type: String,
				required: true,
			},
			fallbackType: {
				type: String,
				required: false,
			},
			legendTitleCharacterLimit: {
				type: Number,
				default: 20,
			},
			showTopN: {
				type: Number,
				default: 0,
			},
			otherLabel: {
				type: String,
				default: 'Other',
			},
			fallbackLabelsToReplace: {
				type: Object,
			},
			baseColor: {
				type: String,
				default: '#8497FF',
			},
		},
		components: {
			AppBarChart,
		},
		mixins: [DateFilteringMixin],
		mounted() {
			this.fetchData()
		},
		data() {
			return {
				data: {},
				formattedData: {},
				tick: 0,
			}
		},
		computed: {
			showLabel() {
				return !!this.label
			},
			showChart() {
				return this.data && Object.keys(this.data.totals || {}).length && !this.$commonHelper.isEmptyObject(this.data)
			},
			formatterFunction() {
				if (this.type === this.$constants.CHART_TYPES.REVENUE_SOURCE_BREAKDOWN) {
					return val => this.$commonHelper.formatCurrency(val, 'USD', 'en-US', 0)
				}
				return val => val
			},
		},
		methods: {
			async fetchData() {
				try {
					const response = await this.makeRequest(this.type)

					if (response && response.data && response.data.total_current > 0) {
						this.data = response.data
						this.tick++
					} else if (this.fallbackType) {
						// If we have a fallback endpoint if there's nothing to show
						const fallbackResponse = await this.makeRequest(this.fallbackType)
						if (fallbackResponse && fallbackResponse.data) {
							// If we have labels we want to change on the frontend, we pass [key]: value
							// And we'll swap the [key] of the response with the new value, which will show
							// As the bar label.
							if (this.fallbackLabelsToReplace) {
								for (const oldLabel in this.fallbackLabelsToReplace) {
									fallbackResponse.data.totals = commonHelper.replaceBarDefaultLabel(
										fallbackResponse.data.totals,
										oldLabel,
										this.fallbackLabelsToReplace[oldLabel]
									)
								}
							}

							this.data = fallbackResponse.data
							this.tick++
						}
					}
				} catch (error) {
					console.error('Error fetching data: ', error)
				}
			},
			formatData() {},
			async makeRequest(requestType) {
				const response = await this.$axios.get(`v1/analytics/chart/${requestType}`, {
					params: {
						timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
						day_range: this.range,
						period_length: this.periodLength,
						end_date: Math.round(this.endDate.getTime() / 1000),
						show_sum_series: 1,
					},
				})
				return response
			},
		},
		watch: {
			showChart(newValue) {
				if (newValue) {
					this.$emit('loaded', this.type)
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	.title-label {
		color: var(--stan-primary-text-color);
		font-size: 18px;
	}
</style>
