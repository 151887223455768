<template>
	<span class="pill-wrapper para-3" :class="{ active: active }" @click="handleClick">
		<slot></slot>
	</span>
</template>

<script>
	import { mapGetters } from 'vuex'

	export default {
		name: 'AppPill',
		props: {
			name: { type: String, default: '' }, // For Analytics Purpose
			active: Boolean,
		},
		computed: {
			...mapGetters('Auth', ['user']),
		},
		methods: {
			handleClick() {
				if (this.name) {
					this.$stanAnalytics('admin-app-pill-click', {
						meta: { user_id: this.user?.user_id, username: this.user?.username },
						props: {
							pill_name: this.name,
						},
					})
				}

				this.$emit('click')
			},
		},
	}
</script>

<style lang="scss" scoped>
	.pill-wrapper {
		cursor: pointer;
		display: flex;
		justify-content: center;
		width: 86px;
		height: 30px;
		padding: 7px 13px;
		gap: 10px;
		border-radius: 20px;
		background-color: var(--stan-gray-soft-color);
		color: var(--stan-text-dark-color);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		&.active {
			background-color: var(--stan-primary-primary-color);
			color: var(--stan-white);
		}
	}
</style>
