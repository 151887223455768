var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _vm.showLabel
      ? _c(
          "div",
          { staticClass: "chart-title mb-3" },
          [
            _vm.iconSrc
              ? _c("InlineSvg", {
                  staticClass: "title-icon",
                  attrs: { src: _vm.iconSrc }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "h4",
              { staticClass: "title-label text-truncate text-bold mb-4" },
              [_vm._v(_vm._s(_vm.label))]
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "chart-wrapper" },
      [
        _c("AppBarChart", {
          attrs: {
            color: _vm.baseColor,
            showTopN: _vm.showTopN,
            otherLabel: _vm.otherLabel,
            totals: _vm.data.totals,
            loading: !_vm.showChart,
            formatter: _vm.formatterFunction
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }