<template>
	<div class="position-relative">
		<div class="order-bump-section" :class="{ 'creator-pro-feature-not-allowed': !isOrderBumpAllowed }">
			<div class="mb-3 text-sm ">
				{{
					$t(
						'Incentivize your customers to purchase more with a one-time offer in your checkout flow and watch your sales increase by ~20%.'
					)
				}}
				<a href="https://help.stan.store/article/37-order-bumps" target="_blank" :tabindex="isOrderBumpAllowed || !isPaid ? 1 : -1">{{
					$t('Learn more')
				}}</a>
			</div>
			<div class="d-flex flex-column">
				<div class="d-flex justify-content-start align-items-center ">
					<label class="text-bold mr-2 mb-0" :class="{ 'text-light': !isOrderBumpAllowed || !isPaid || !dropdownEnabled }"
						>Enable Order Bump</label
					>
					<AppToggle v-model="value.enabled" :disabled="!isOrderBumpAllowed || !isPaid || !dropdownEnabled" />
				</div>
				<div class="form-group mt-3 mb-0" v-if="!isOrderBumpAllowed || !isPaid || !dropdownEnabled">
					<Tip :tipSource="orderBumpIssue" />
				</div>
			</div>
			<AppAccordion>
				<AppAccordionItem class="mb-0 overflow-visible shadow-none mt-3" :showHeader="false" :isWrapper="true" :name="accordionId">
					<AppDropdown
						class="form-control"
						:key="dropdownComponentKey"
						v-model="value.page_id"
						:options="pageOptions"
						label="title"
						reduce="id"
						:searchable="false"
						:placeholder="$t('Select Option')"
						:disabled="!dropdownEnabled || !isPaid"
					/>
					<div class="form-group mt-3 mb-0">
						<Tip :tipSource="orderBumpInfo" />
					</div>
				</AppAccordionItem>
			</AppAccordion>
		</div>
		<AppUpgrade title="Want to include a one-time offer to your checkout flow?" v-if="!isOrderBumpAllowed" />
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'
	import Tip from '~/pages/stores/components/Tip'
	import AppUpgrade from '~/components/shared/AppUpgrade'
	import constants from '~/global_helper/constants.js'

	export default {
		name: 'OrderBumpForm',
		components: {
			Tip,
			AppUpgrade,
		},
		props: {
			store: {
				type: Object,
				default: () => {},
			},
			isPaid: {
				type: Boolean,
				isPaid: true,
			},
			pageId: {
				type: Number,
				default: -1,
			},
			value: {
				type: Object,
				default: () => {},
			},
			validation: {
				type: Object,
				default: () => {},
			},
		},
		mounted() {
			this.checkEnabled()
		},
		data() {
			return {
				applicableProductTypes: [constants.PRODUCT_TYPES.DIGITAL_DOWNLOAD, constants.PRODUCT_TYPES.COURSE, constants.PRODUCT_TYPES.COMMUNITY],
				accordionId: 'order-bump-accordion',
				dropdownComponentKey: 0,
			}
		},
		computed: {
			...mapGetters('Auth', ['user']),
			...mapGetters('Communities', ['communityIsActive']),
			pageOptions() {
				let options = []
				if (this.store?.pages) {
					options = this.store.pages
						.filter(p => p.page_id !== this.pageId)
						.filter(p => p.status === 2)
						.filter(p => this.applicableProductTypes.indexOf(p.type) > -1)
						.filter(p => this.isPaidProduct(p.data.product))
						.filter(p => !p.data.product.price.interval)
						.map(p => ({
							id: p.page_id,
							title: p.data.product.title,
							image: p.data.location === 'store' && p.store_id === this.store.store_id ? p.data.button.image : p.data.product.image,
						}))
				}
				return options
			},
			dropdownEnabled() {
				return this.pageOptions.length > 0
			},
			isOrderBumpAllowed() {
				return this.user.subscription_tier_allowed_modules?.indexOf('order-bump') !== -1
			},
			orderBumpInfo() {
				return {
					description: 'Order bumps can’t be free and must be paid <b>Digital Download</b>, <b>Course</b>, or <b>Community products.</b>',
					customIcon: '/images/icons/ic-info-purple.svg',
					smallIcon: true,
					severity: 'neutral',
				}
			},
			orderBumpIssue() {
				const description = this.dropdownEnabled
					? '<b>Order bumps can only be added to paid products.</b> To add an order bump to this product, update the product price to be at least $0.50.'
					: '<b>There are no eligible paid products to add as an order bump.</b> Please add or update a Digital Download, Community, or Course product priced at least $0.50.'
				return {
					description,
					customIcon: '/images/icons/ic-info-dark.svg',
					severity: 'info',
				}
			},
		},
		methods: {
			checkEnabled() {
				if (this.value.enabled) {
					if (this.dropdownEnabled) {
						this.value.page_id = this.value.page_id > -1 ? this.value.page_id : this.pageOptions[0].id
					} else {
						this.value.page_id = -1
						this.value.enabled = false
					}
					this.dropdownComponentKey += 1
				}
				$(`#collapse${this.accordionId}`).collapse(this.value.enabled ? 'show' : 'hide')
			},
			isPaidProduct(product) {
				let price = 0
				if (product.price) price = product.price.sale_amount ? product.price.sale_amount : product.price.amount
				return price > 0
			},
		},
		watch: {
			dropdownEnabled() {
				this.checkEnabled()
			},
			'value.enabled': {
				handler() {
					this.checkEnabled()
				},
			},
		},
	}
</script>
